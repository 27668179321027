@import "common/alerty";
@import "bootstrap/variables";
@import "~bootstrap/scss/bootstrap";
//@import "../node_modules/bootstrap/scss/custom-forms";
@import "bootstrap/hacks";

$fa-font-path: 'fonts';
//@import '../../node_modules/font-awesome/scss/font-awesome';
@import 'glowna/jumbotron';
@import 'cennik/cennik';
@import 'common/logo';
@import 'common/fa-loader';

//@import '../../node_modules/aos/src/sass/aos';
//@import '../../node_modules/nouislider/distribute/nouislider.min.css';

textarea {
  height: 200px;
}

.jumbotron {
  margin-bottom: 0;
}

.mozliwosci {
  .row {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.stopka {
  font-size: 80%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.container.main {
  margin-top: 9rem;
}

//@include media-breakpoint-down(sm) {
//  .display-3 {
//    font-size: 3em;
//  }
//  .tytul-sekcji {
//    font-size: 2.5rem;
//  }
//}

