$loader-background-color: #fff;
$loader-background-opacity: .8;
$loader-color: #444;
$loader-font-size: 5rem;

.loader {
  position: relative;
  z-index: 98;
  min-height: 50px;
  &:before {
    content: "";
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $loader-background-color;
    opacity: $loader-background-opacity;
  }
  &:after {
    z-index: 100;
    //color: $loader-color;
    //@extend .fa-pulse; //
    font-size: $loader-font-size;
    position: absolute;
    top: 60%;
    left: 50%;
    margin: (-$loader-font-size / 2) 0 0 (-$loader-font-size / 2);
    content: " ";
    display: block;
    width: $loader-font-size;
    height: $loader-font-size;
    //margin: 8px;
    border-radius: 50%;
    border: $loader-font-size/10 solid $loader-color;
    border-color: $loader-color transparent $loader-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
