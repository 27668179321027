.logo {
  border: 1px solid $gray-200;
  border-radius: 5px;
  padding: 10px;
  width: 170px;
  height: 96px;
  display: inline-block;
  & > img {
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0%);
    }
  }
}
