$primary: #394a71 !default;

// Carousel
$karuzelaKolor: rgba(0, 0, 0, .3);
$carousel-text-shadow: none;//0 1px 2px rgba(0, 0, 0, .6) !default;

$carousel-control-color: $karuzelaKolor !default;
$carousel-control-width: 10% !default;
$carousel-control-sm-up-size: 30px !default;
$carousel-control-opacity: .0 !default;
$carousel-control-font-size: 20px !default;

$carousel-indicators-width: 60% !default;

$carousel-indicator-size: 10px !default;
$carousel-indicator-active-size: 12px !default;
$carousel-indicator-active-bg: $karuzelaKolor !default;
$carousel-indicator-border-color: $karuzelaKolor !default;

$carousel-caption-width: 70% !default;
$carousel-caption-sm-up-width: 60% !default;
$carousel-caption-color: 000 !default;

$carousel-icon-width: 20px !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        //xxl: 1320px
);

