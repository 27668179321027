.jumbotron {
  &.komputer {
    position: relative;
    overflow: hidden;
    display: block;
    background: transparent;
    &::after {
      background: white url('/assets/img/bg5.jpg') 0 50%;
      background-size: cover;
      opacity: .3;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

.zrzuty {
  margin-top: -2rem;
}

.tytul-sekcji {
  @extend .display-4;
  @extend .text-sm-center;
  @extend .pb-3;
}
