.cennik {
  .range {
    height: 1.2rem;
    margin: 5rem 0;
    @include media-breakpoint-down(sm) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
  }
  h1 {
    @extend .text-primary;
    font-size: 5rem;
    @include media-breakpoint-only(sm) {
      font-size: 4rem;
    }
  }
  .rok h1 {
    @extend .text-danger;
  }
  h2 {
    font-size: 2.5rem;
  }

  .noUi-tooltip {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  .noUi-handle {
    background: $primary;
    border-color: $primary;
    box-shadow: none;
  }
}
